
  
  .contentdatas{
      position: fixed;
      background-color: rgba(0, 0, 0, 0.449) !important;
      width: 100%;
      top: 0;
      left: 0;
      height: 100vh;
      /* width: 64px; */
      /* height: 64px; */
      z-index: 99999;
}
@keyframes bg {
    0% {
      background: #000;
    }
    100% {
        background: rgb(172, 20, 20);
    }
  }
  h1{
    font-size: 64px;
    transition: 0.5s;
}
.loading {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 38%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading div {
    position: absolute;
    background: rgb(10, 34, 141);
    opacity: 1;
    border-radius: 50%;
    animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .loading div:nth-child(2) {
    animation-delay: -.7s;
  }
  @keyframes loading {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }
  
  
  .fa-instagram{
    position: absolute;
    color: #000;
    top: 3%;
    right: 2%;
    font-size: 38px;
  }
  .fa-instagram:hover{
    font-size: 42px;
    color: #981919;
    transition: all .1s linear;
    cursor: pointer;
  }