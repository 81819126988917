/* custom-tree.css */

.node__root > circle {
    fill: #ff6652 !important;
  }
  
  .node__branch > circle {
    fill: #4e2fa9 !important;
  }
  
  .node__leaf > circle {
    fill: #ff6652 !important;
    /* Let's also make the radius of leaf nodes larger */
    /* r: 40; */
  }