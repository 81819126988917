@tailwind base;
@tailwind components;
@tailwind utilities;

.global-table .mail {
  font-family: "Manrope", serif !important;
  @apply text-[12px] leading-5 font-medium text-[#84818A] !important;
}

.global-table .name {
  font-family: "Manrope", serif !important;
  @apply text-sm leading-5 font-semibold text-[#2E2C34] !important;
}
.global-table .contact {
  background: rgba(0, 165, 255, 0.1);
  border-radius: 4px;
  @apply text-[#00A5FF] text-[12px] font-semibold px-2 py-1 inline-block;
}
.pagination {
  mix-blend-mode: normal;
  box-shadow: 0px -1px 10px rgb(0 0 0 / 10%);
  @apply p-2 bg-white flex items-center mt-5;
  margin-left: 15px;
  @media (max-width: 1399px) {
    margin: 15px 0 15px 15px;
  }
}
.css-ccgeng-MuiPaper-root-MuiDialog-paper{
  max-height: 100% !important;
}
.form-name {
  @apply text-[#4E2FA9] text-base font-semibold;
}
.rolePOPUP .MuiDialog-paper {
  @apply w-[720px] p-4 rounded-xl;
}
.addRole .title {
  @apply text-[#2E2C34] font-semibold text-base;
}
.addRole .para {
  @apply text-[#84818A] text-sm font-medium;
}
.addRole .pera-text {
  @apply text-[#84818A] text-sm font-medium pl-8;
}
.addRole .checkbox-addrole {
  @apply pt-6;
}
.addRole .checkbox-addrole span {
  @apply pt-0 pb-0;
}
.addRole .divider {
  background: #ebeaed;
  transform: matrix(1, 0, 0, -1, 0, 0);
  @apply h-[1px] w-full my-4;
}
.breadCrumb {
  @apply flex items-center gap-6 py-10;
}
.breadCrumb span {
  @apply text-2xl leading-5 text-[#2E2C34] font-semibold;
}
.planPopUP .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  @apply max-w-[1243px] p-8 rounded-[20px];
}
.planPopUP .bazaarButtons {
  @apply flex items-center gap-4;
}
.planPopUP .closeBtn {
  width: 20px;
  height: 20px;
  background: url(../icons/close-icon.svg) no-repeat center center;
}
.css-dupyg1{
  display: block !important;
  width: 100%;
}
.roles_premission_section{
  padding: 25px 0 0;
}
.pagination {
  display: none;
}
.planPopUP .css-1t1j96h-MuiPaper-root-MuiDialog-paper .pagination {
  display: flex;
}
.w-\[50px\] {
  width: 50px !important;
}
.groupCeta tr td:not(:first-child) ,
.groupCeta tr th:not(:first-child){
  @apply pl-6;
}
.groupCeta tr {
  display: flex;
  align-items: center;
}
.groupCeta .ActionLogo {
  border: 1px solid #ebeaed;
  border-radius: 4px;
  cursor: pointer !important;
  @apply flex gap-3 items-center p-[14px];
}
.groupCeta .ActionLogo img{
  cursor: pointer !important;
  @apply max-w-[16px]
}
.groupCeta .ActionLogo .dividor{
  border-left: 2px solid #ebeaed;
  width: 4px;
  height: 22px;
}
.rightContainer .addButton{
  border: 2px dashed #5542F6;
border-radius: 4px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 11px 24px;
gap: 8px;
cursor: pointer;
color: #5542F6;
font-weight: 600;
font-size: 14px;
line-height: 20px;
/* margin-top: 32px; */
}
.css-18hffm8 .rightContainer{
  padding-top: 0 !important;
}
.rightContainer .addButton img{
  max-width: 10px;
}
.border-r-1{
  border-right: 1px solid #e1e1e1;
}
.css-dupyg1{
  display: block !important;
  width: 100%;
}
.app-active {
  position: relative;
}
.app-active::before {
  content: '';
  background-color: #000000a8;
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  z-index: 9999;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}